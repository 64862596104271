import "./App.css";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { CustomImage, images } from "./images";
import { useReducer, useState } from "react";

function App() {
  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index: number, item: CustomImage) => {
    setIndex(index);
    setTimeout(() => {
      forceUpdate();
    }, 1000);
  };
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  return (
    <div style={{ margin: 0 }}>
      <div
        style={{
          color: "#c9c9c9",
          paddingTop: 10,
          paddingBottom: 10,
          marginLeft: 10,
          width: "160px",
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <a
          style={{
            fontSize: "24px",
            cursor: "pointer",
            marginLeft: 10,
            marginRight: 10,
            textDecoration: "none",
            color: "#c9c9c9",
          }}
          href={"https://alpdogan.co"}
        >
          {"<"}
        </a> */}
        <span>gallery.alpdogan.co</span>
      </div>
      <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
      />
      {!!currentImage && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
          animationDisabled={true}
        />
      )}
    </div>
  );
}

export default App;
